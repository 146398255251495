@font-face {
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}

@font-face {
  font-family: 'Corbel';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/Corbel.woff2') format('truetype');
}
