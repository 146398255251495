$primary: #272e5a;
$secondary: #fac022;
$success: #39c322;
$danger: #ba1a1a;
$border-card: #eee;
$background-card: #fafafa;
$border-inner-card: #f4f3f3;
$background-info-gray: #e4e4e4;
$background-inner-card: #fff;
$background-icon-sidebar: #c7c7c7;
$background-body: #f4f4f4;
$background-accent: rgba($secondary, 0.5);
$background-light-yellow: #fff9e9;
$border-color: #e0e0e0;
$dashboard-gray: #7e8491;
$scrollbar-track: #f5f5f5;
$scrollbar-thumb: #e0e0e0;
$scrollbar-border-thumb: #9e9e9e;
$grid-column-line: #e1e1e1;
$icon-action-button: #7c7c7c;
$progress-bar: #c7c7c8;
$gray-text: #6b7280;
$border-light-blue: #98a5fd;
$border-light-yellow: #d7a300;
$dark-gray: #757575;
$light-gray: #b8b8b8;
$comments-dialog-header: #272e5a;
$background-hover-row: rgba(234, 246, 255, 0.87);
$background-selected-row: rgba(234, 246, 255, 1);
$alert-color: #c33838;
$yellow-text: #ddb800;