@use '@angular/material' as mat;
@use 'fonts';
@use 'typography';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$my-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$my-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);

@include mat.all-component-themes($my-app-theme);

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  position: fixed;
  height: 100%;
  overflow: auto;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: #1e2345;
}

.submit-btn {
  height: 60px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  border-radius: 8px !important;
}

.mat-mdc-menu-content {
  min-width: 200px;
}

button {
  u {
    color: #fac022;
  }
}

mat-label {
  text-align: left;
  font-size: 14px !important;
}

mat-icon {
  color: #73778c !important;
}

.mat-mdc-text-field-wrapper {
  background: #292e4e !important;
  // border: 1px solid #4d516a !important;
  border-radius: 6px !important;
}

.mat-mdc-text-field-wrapper:hover {
  border-color: #73778c !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading {
  border-color: #73778c;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #73778c;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing:hover {
  border-color: #73778c;
}

// error

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading {
  border-color: #f44336;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #f44336;
}

/* .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #4d516a !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #4d516a !important;
}

mat-form-field.mat-mdc-form-field {
  color: #4d516a !important;
} */

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

input {
  color: #dbdbdb !important;
}

input::placeholder {
  color: #4d516a !important;
}

.mat-mdc-form-field-infix {
  min-height: 46px !important;
  padding: 11px 0px !important;
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #fac022 !important;
}

main {
  background: #1e2345;
}

.content {
  display: flex !important;
  form {
    max-width: 350px;
  }
}

.small-logo {
  width: 100px;
  height: 44px;
}

.big-logo {
  width: 200px;
  height: 86px;
}

.passwords-match-container {
  font-size: 14px;
  margin-bottom: 16px;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.required {
  color: #f44336 !important;
}

mat-form-field {
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

goatsports-change-password {
  mat-form-field {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 16px !important;
    }
  }
}

.error-space {
  margin-bottom: 16px;
}

.incorrect-password-format {
  margin-bottom: 48px !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: spinner 0.8s linear infinite;
  box-sizing: border-box;
  margin-top: -10px;
  margin-left: -10px;
  border: 3px solid white;
  border-top-color: #1e2345;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  content: '';
}

input {
  caret-color: #fac022 !important;
}

.grid-container {
  // height: unset !important;
}

.mdc-button__label {
  letter-spacing: 0px !important;
}
